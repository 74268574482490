/**
 * Helper: Accessibility
 * -----------------------------------------------------------------------------
 * Utility classes to aid in creating visually accessibile components.
 *
 */

/**
 * Default focus styling for browsers.
 */
 @mixin focus-ring() {
  outline: -webkit-focus-ring-color auto 5px;
}

/**
 * Skip to content button.
 * - Overrides .visually-hidden when focused.
 */
.skip-link:focus {
  @include visually-shown(absolute);
  background-color: $color-background-light;
  color: $color-text-primary;
  padding: $spacing-xs;
  transition: none;
  z-index: $layer_pop_out;
}

/**
 * Reset focus state on tabbable elements.
 */
[tabindex],
[draggable],
a[href],
area,
button:enabled,
input:not([type=hidden]):enabled,
object,
select:enabled,
textarea:enabled {
  &:focus {
    outline: 0;
  }

  .is-tabbable & {
    &:focus {
      @include focus-ring;
    }
  }
}
