body {
    display: flex;
    flex-direction: column;
    font-family: 'Libre Franklin', sans-serif;
    font-size: 14px;
    color: #373737;
    font-weight: 400;
}

* {
    margin: 0;
    padding: 0;
    text-rendering: auto;
}

img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    height: auto;
}

a:hover,
a:focus {
    outline: none;
    text-decoration: none
}

.top-sec {
    padding: 0 0 0 0;
}

.float-right {
    float: right;
}

.top-menu {
    float: right;
}

.logo {
    padding: 15px 0 0 0;
}

.navbar-default {
    background-color: transparent;
    border-color: transparent;
    margin: 0;
    min-height: auto;
    border: none;
}

.navbar-default .navbar-nav>li>a {
    color: #303030;
    padding: 28px 20px;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    text-transform: capitalize;
}

.navbar-default .navbar-nav>li>a::after {
    content: '';
    background: #333;
    width: 4px;
    height: 1px;
    position: absolute;
    right: 0;
    top: 37px;
}

.navbar-default .navbar-nav>li:last-child a::after {
    display: none;
}

.navbar-default .navbar-nav>li:last-child a {
    padding: 6px 20px 6px 40px;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border-color: transparent;
    padding: 0;
}

.navbar-default .navbar-nav>li:last-child a::after,
.navbar-default .navbar-nav>li:nth-last-child(2) a::after {
    display: none;
}

.navbar-nav .lastmenuli a {
    display: inline-block;
    min-width: 240px;
    margin: 0;
    margin-bottom: 10px;
    padding: 16px;
    border: 1px solid #1E8DE8;
    background-color: #1D77B8;
    font-family: 'Montserrat';
    font-weight: 700;
    max-width: 240px;
    max-height: 50px;
    color: #fff !important;
    font-size: 20px;
    line-height: 20px;
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
}

.navbar-nav .lastmenuli {
    float: right;
    margin-top: 15px;
}

.navbar-collapse .top-anchor {
    background: none;
}

.navbar-nav .lastmenuli a:hover {
    background: #105f94;
    color: #fff;
}

.ml-auto.desktop {
    display: none;
}

.header-text {
    padding: 85px 0 150px 0;
    text-align: center;
    border-top: 1px dashed #a2a2a2;
}

.header-text h1 {
    color: #454546;
    font-weight: 800;
    font-size: 60px;
    line-height: 66px;
    letter-spacing: 1px;
}

.menu-container {
    background: transparent;
}

.menu-container nav.navbar.navbar-expand-lg {
    padding: 23px 0;
}

@media (max-width: 767px) {
    .navbar {
        .container-fluid {
            flex-direction: column;
            justify-content: center;
            gap: 16px;

            .navbar-brand {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

@media (max-width: 575px) {
    .menu-container nav.navbar.navbar-expand-lg {
        padding: 13px 0;
    }
}

.menu-container .desh {
    padding: 9px 6px;
    color: #000000;
}

.menu-container button {
    border: 1px solid;
    background: #8bc340;
    font-family: Libre Franklin;
    font-size: 15px;
}

.menu-container button i.fa.fa-paper-plane {
    background: #efe711;
    padding: 6px;
    color: #000;
    border-bottom: 2px solid #bcb60e;
    border-radius: 3px;
    margin: 0 0 0 10px;
}

.menu-container a {
    color: #000 !important;
    font-family: Libre Franklin;
    font-weight: 400;
}

.menu-container a:hover {
    color: #fff;
}

footer {
    background-color: rgb(37, 40, 48);
    color: rgb(181, 181, 181);
}

footer a {
    color: rgb(181, 181, 181);
}

div.container {
    width: 100%;
    max-width: 1150px;
    padding-left: 35px;
    padding-right: 35px;
}

.navbar-nav .lastmenuli {
    margin: 0;
}

.btn-hide {
    display: none;
}

.navbar-brand {
    max-width: 258px;
    cursor: pointer;
}

@media (max-width: 575px) {
    .navbar-brand {
        max-width: 358px;
    }
}

main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.border-sep.full {
    width: 99%;
    margin: auto auto 43px;
}

.border-sep {
    border-bottom: 1px solid rgba(199, 199, 199, 0.933);
    margin: 70px 0px 45px;
    position: relative;
    width: 586px;
    max-width: 100%;
}

.border-sep::after {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: rgb(199, 199, 199);
    position: absolute;
    bottom: -4px;
    left: auto;
    right: 0px;
}

.border-sep::before {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: rgb(199, 199, 199);
    position: absolute;
    bottom: -4px;
    left: 0px;
}

.hide {
    display: none !important;
}