@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/variables';

//////////////////////////////
// BREAKPOINTS
//////////////////////////////

$breakpoint-xs-mobile: 375px;
$breakpoint-mobile: 575px;
$breakpoint-xs-width: 767px;
$breakpoint-s-width: 991px;
$breakpoint-m-width: 1024px;
$breakpoint-mh-width: 1170px;
$breakpoint-l-width: 1600px;
$breakpoint-xl-width: 1920px;
$breakpoint-2xl-width: 2250px;

$layer_negative: -1;
$layer_base: 0;
$layer_flat: 1;
$layer_raised: 2;
$layer_sticky: 8;
$layer_overlay: 12;
$layer_temporary: 16;
$layer_pop_out: 24;

$default-transition: ease-in 0.2s all;

$border-color: #DCE3F4;
$border-color-dark: darken($border-color, 10%);
$border-color-brand: $primary;
$border-radius: 0.25rem;
$border-radius-lg: 0.625rem;
$border-radius-sm: 0.25rem;

$spacer: 0.5rem;

$spacing-3xs: 0.125rem;
$spacing-2xs: 0.25rem;
$spacing-xs: 0.5rem;
$spacing-s: 0.75rem;
$spacing-m: 1rem;
$spacing-l: 1.5rem;
$spacing-xl: 2rem;
$spacing-2xl: 2.5rem;
$spacing-3xl: 3rem;

$font-size-base: 1rem;
$font-family-sans-serif: 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
'Noto Sans', sans-serif;
$font-family-base: $font-family-sans-serif;
$font-weight-semibold: 500;

$headings-font-family: 'Mulish';

$weight-thin: 100;
$weight-extra-light: 200;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;
$weight-heavy: 800;
$weight-black: 900;

$size-3xs: 0.125rem;
$size-2xs: 0.25rem;
$size-xs: 0.5rem;
$size-s: 0.75rem;
$size-m: 1rem;
$size-l: 1.5rem;
$size-xl: 2rem;
$size-2xl: 2.5rem;
$size-3xl: 3rem;

$line-height-body: 1.5;
$line-height-display: 1.1;

$palette-grey: hsl(192, 4%, 47%);
$palette-0: #f3f3f3;
$palette-1: #2852A1;
$palette-2: #0072b8;
$palette-3: #0085b5;
$palette-4: #00949f;
$palette-5: #00a07b;
$palette-6: #34a853;
$palette-gray: $palette-grey;

$primary: $palette-1;
$secondary: lighten($palette-1, 10%);
$info: #1e8de8;
$success: #48b328;
$warning: #fcd70b;
$danger: #b71313;
$light: #f8f9fa;
$dark: #343a40;
$color-primary: $primary;
$color-secondary: $secondary;

$colors: ();
$colors: map-merge(
                (
                        'blue': $blue,
                        'indigo': $indigo,
                        'purple': $purple,
                        'pink': $pink,
                        'red': $red,
                        'orange': $orange,
                        'yellow': $yellow,
                        'green': $green,
                        'teal': $teal,
                        'cyan': $cyan,
                        'white': $white,
                        'grey': $gray-600,
                        'grey-dark': $gray-800
                ),
                $colors
);

$theme-colors: ();
$theme-colors: map-merge(
                (
                        'primary': $primary,
                        'secondary': $secondary,
                        'success': $success,
                        'info': $info,
                        'warning': $warning,
                        'danger': $danger,
                        'light': $light,
                        'dark': $dark
                ),
                $theme-colors
);

$color-text-primary: $black;
$color-text-secondary: #373F41;
$color-text-light-grey: #525657;
$color-text-dark-grey: #293737;
$color-text-white: $white;
$color-text-inverse: $color-text-white;
$color-text-brand: $primary;

$color-background-black: $black;
$color-background-dark: $color-text-dark-grey;
$color-background-light: $color-text-light-grey;
$color-background-lighter: rgba(244, 245, 244, 0.19);
$color-background-grey: #E5E5E5;
$color-background-light-grey: $palette-0;
$color-background-brand: $primary;
$color-background-white: $white;

$header-title-color: #373f41;

$link-color: $color-text-secondary;
$link-hover-color: darken($link-color, 10%);


